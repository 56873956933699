<template>
  <v-container fluid>
    <v-navigation-drawer app class="ma-0 pa-0" v-model="mostrar">
      <opciones />
    </v-navigation-drawer>
    <v-app-bar app>
      <v-app-bar-nav-icon @click="mostrar = !mostrar"></v-app-bar-nav-icon>
      <h3>Listado de Usuario</h3>
    </v-app-bar>
    <v-container elevation="10" fluid>
      <listUser />
    </v-container>
  </v-container>
  <!-- <v-container fluid>
    <listUser />
  </v-container> -->
</template>

<script>
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      mostrar: false,
    };
  },
  components: {
    opciones: () => import("@/components/comun/opciones.vue"),
    listUser: () =>
      import("../../components/Calculadora/listUserCalcComponent.vue"),
  },
  mounted() {
    let opciones = [
      {
        id: 1,
        nombre: "Carga Masiva",
        // class: "active",
        url: "carga_masiva",
      },
      {
        id: 2,
        nombre: "USUARIOS CALC",
        class: "active",
        url: "list_user_calc",
      },
      // {
      //   id: 2,
      //   nombre: "FCL",
      //   class: "active",
      //   url: "carga_masiva_fcl",
      // },
      // {
      //   id: 3,
      //   nombre: "AÉREO",
      //   url: "reporte_abierta",
      // },
    ];
    this.opcioneMenuLista(opciones);
  },
  methods: {
    ...mapMutations(["opcioneMenuLista"]),
  },
};
</script>

<style></style>
